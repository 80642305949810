import { selectProductThumbnail } from '@storefront/util/auction/selectProductThumbnail';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import type { Auction } from 'storefront-client';
import type { Product } from 'storefront-client';

interface AuctionDataProps {
  auction: Auction;
}

const useAuctionData = ({ auction }: AuctionDataProps) => {
  const computedAuctionData = useMemo(() => {
    const isGoodForPets = auction.product.tags?.some((tag) => tag.value === 'pet-friendly');
    const originalPrice = Math.max(auction.msrp ?? 0, auction.asking_price ?? 0);
    const discountedPrice = auction.current_price;
    const priceDropTimeInMinutes = Math.round(
      Math.abs(DateTime.fromISO(auction.last_price_drop_date).diffNow('minutes').minutes),
    );
    const imageSrc = selectProductThumbnail(auction.product);
    const inventoryCount =
      auction.product.variants?.reduce((acc, variant) => acc + (variant?.inventory_quantity ?? 0), 0) ?? 0;
    const bidCount = auction.bid_count_active;
    const itemHasBeenSold = auction.status === 'COMPLETED';
    const percentageDiscount = Math.round(Math.abs((originalPrice - discountedPrice) / originalPrice) * 100);
    const hasDiscountBiggerThan30Percent = percentageDiscount >= 30;
    const upcomingAuctionTime = DateTime.fromISO(auction.start_date).diffNow('minutes').minutes;
    const upcomingAuction = upcomingAuctionTime > 0;
    const auctionWillEndSoon =
      DateTime.fromISO(auction.end_date).diffNow('hours').hours > 0 &&
      DateTime.fromISO(auction.end_date).diffNow('hours').hours <= 24 &&
      !upcomingAuction;

    // TODO: implement when the auction was sold
    // const auctionCompletedDate = auction.completed_at;

    return {
      isGoodForPets,
      originalPrice,
      discountedPrice,
      priceDropTimeInMinutes,
      imageSrc,
      inventoryCount,
      bidCount,
      itemHasBeenSold,
      percentageDiscount,
      hasDiscountBiggerThan30Percent,
      upcomingAuctionTime,
      upcomingAuction,
      auctionWillEndSoon,
    };
  }, [auction]);

  return computedAuctionData;
};

export default useAuctionData;
